import { Network } from "@converge-collective/common/models/Network";
import { SvgIconProps } from "@mui/material";
import dynamic from "next/dynamic";

export type NavItem = {
  label: string;
  icon?: React.ComponentType<SvgIconProps>;
  target?: string;
} & (
  | {
      onClick: () => void;
      url?: never;
    }
  | {
      url: string;
      onClick?: never;
    }
);

export const mainNav = (network: Network): NavItem[] => [
  ...(network.settings?.areDocsDisabled === true
    ? []
    : [
        {
          url: docsRoute(network.slug),
          label: "Docs & Files",
          icon: dynamic(() => import("@mui/icons-material/Description")),
        },
      ]),
  ...(network.settings?.areCoursesEnabled === true
    ? [
        {
          url: coursesRoute(network.slug),
          label: "Courses",
          icon: dynamic(() => import("@mui/icons-material/School")),
        },
      ]
    : []),
  {
    url: eventsRoute(network.slug),
    label: "Events",
    icon: dynamic(() => import("@mui/icons-material/Event")),
  },

  ...(network.settings?.isMarketplaceEnabled === true
    ? [
        {
          url: marketplacesRoute(network.slug),
          label: "Marketplace",
          icon: dynamic(() => import("@mui/icons-material/EmojiPeople")),
        },
      ]
    : []),

  ...(network.settings?.isRewardsEnabled === true
    ? [
        {
          url: rewardsRoute(network.slug),
          label: "Rewards",
          icon: dynamic(() => import("@mui/icons-material/Stars")),
        },
      ]
    : []),

  ...(network.settings?.isDirectoryDisabled === true
    ? []
    : [
        {
          url: directoryRoute(network.slug),
          label: "Directory",
          icon: dynamic(() => import("@mui/icons-material/ImportContacts")),
        },
      ]),

  // ...(network.settings?.isJobsEnabled === true
  //   ? [
  //       {
  //         url: jobsRoute(network.slug),
  //         label: "Jobs",
  //         icon: dynamic(() => import("@mui/icons-material/Description")),
  //       },
  //     ]
  //   : []),

  // { url: ulyssesPactRoute(network.slug), label: "Ulysses Pact" },
];

/** Navigation items for the network admin sidebar */
export const networkAdminNav = (network: Network): NavItem[] => [
  {
    url: networkAdminRoute(network.slug),
    label: "Settings",
    icon: dynamic(() => import("@mui/icons-material/Settings")),
  },
  {
    url: networkAdminAnalyticsRoute(network.slug),
    label: "Analytics",
    icon: dynamic(() => import("@mui/icons-material/Analytics")),
  },
  {
    url: networkAdminMembersRoute(network.slug),
    label: "Members & Admins",
    icon: dynamic(() => import("@mui/icons-material/People")),
  },
  {
    url: networkAdminRolesRoute(network.slug),
    label: "Roles & Permissions",
    icon: dynamic(() => import("@mui/icons-material/Assignment")),
  },
  {
    url: networkAdminNetworkCategoriesRoute(network.slug),
    label: "Categories",
    icon: dynamic(() => import("@mui/icons-material/Category")),
  },
  {
    url: networkAdminNewsfeedRoute(network.slug),
    label: "Newsfeed",
    icon: dynamic(() => import("@mui/icons-material/Bookmark")),
  },
  {
    url: networkAdminPeerRecognitionRoute(network.slug),
    label: "Recognition",
    icon: dynamic(() => import("@mui/icons-material/EmojiEvents")),
  },
  {
    url: networkAdminRewardsRoute(network.slug),
    label: "Rewards",
    icon: dynamic(() => import("@mui/icons-material/Stars")),
  },
  {
    url: networkAdminRedemptionsRoute(network.slug),
    label: "Redemptions",
    icon: dynamic(() => import("@mui/icons-material/Redeem")),
  },
  {
    url: networkAdminChallengesV1Route(network.slug),
    label: "Challenges",
    icon: dynamic(() => import("@mui/icons-material/EmojiEvents")),
  },
  {
    url: networkAdminTagsRoute(network.slug),
    label: "Manage Tags",
    icon: dynamic(() => import("@mui/icons-material/Description")),
  },

  // {
  //   url: networkAdminJobsRoute(network.slug),
  //   label: "Jobs",
  //   icon: dynamic(() => import("@mui/icons-material/Work")),
  // },

  // TODO re-enable when ready
  // {
  //   url: networkAdminChallengesRoute(network.slug),
  //   label: "Courses",
  //   icon: dynamic(() => import("@mui/icons-material/School")),
  // },
];

export const competitionNav = (network: Network): NavItem[] => [
  {
    url: challengesV1Route(network.slug),
    label: "Challenges",
    icon: dynamic(() => import("@mui/icons-material/EmojiEvents")),
  },
  {
    url: myTeamRoute(network.slug),
    label: "My Team",
    icon: dynamic(() => import("@mui/icons-material/Diversity3")),
  },
  // NOPE leaderboards are dead
  // {
  //   url: leaderboardsRoute(network.slug),
  //   label: "Leaderboards",
  //   icon: dynamic(() => import("@mui/icons-material/Bookmark")),
  // },
];

export const marketingNavItemsLeft = (slug: string): NavItem[] => [
  {
    url: eventsRoute(slug),
    label: "Events",
    icon: dynamic(() => import("@mui/icons-material/Event")),
  },
  {
    url: marketplacesRoute(slug),
    label: "Marketplace",
    icon: dynamic(() => import("@mui/icons-material/EmojiPeople")),
  },
  {
    url: groupsRoute(slug),
    label: "Groups",
    icon: dynamic(() => import("@mui/icons-material/Groups")),
  },
];

export const marketingNavItems = (
  slug: string,
  homeUrl?: string
): NavItem[] => [
  {
    url: homeUrl || newsFeedRoute(slug),
    label: "Home",
    icon: dynamic(() => import("@mui/icons-material/Home")),
  },
  // { url: `${newsFeedRoute(slug)}/sponsorship`, label: "Sponsorships" },
  {
    url: "/contact",
    label: "Contact",

    icon: dynamic(() => import("@mui/icons-material/AlternateEmail")),
  },
  {
    url: "/about",
    label: "About",

    icon: dynamic(() => import("@mui/icons-material/Info")),
  },
];

export const newsFeedRoute = (networkSlug: string): string => `/${networkSlug}`;

/** Course routes */
export const courseSectionRoute = (
  networkSlug: string,
  courseSlug: string,
  blockSlug: string,
  sectionSlug: string
): string =>
  `/${networkSlug}/courses/${courseSlug}/blocks/${blockSlug}/sections/${sectionSlug}`;

export const courseBlockRoute = (
  networkSlug: string,
  courseSlug: string,
  blockSlug: string
): string => `/${networkSlug}/courses/${courseSlug}/blocks/${blockSlug}`;

export const createCourseSectionRoute = (
  networkSlug: string,
  courseSlug: string,
  blockSlug: string
): string =>
  `/${networkSlug}/courses/${courseSlug}/blocks/${blockSlug}/sections/create`;

export const courseRoute = (networkSlug: string, slug: string): string =>
  `/${networkSlug}/courses/${slug}`;

export const coursesRoute = (networkSlug: string): string =>
  `/${networkSlug}/courses`;

/** Event routes */
export const eventsRoute = (networkSlug: string): string =>
  `/${networkSlug}/events`;

export const marketplacesRoute = (networkSlug: string): string =>
  `/${networkSlug}/marketplace`;

export const rewardsRoute = (networkSlug: string): string =>
  `/${networkSlug}/rewards`;

export const eventDetailRoute = (
  networkSlug: string,
  eventSlug: string
): string => `/${networkSlug}/events/${eventSlug}`;

export const eventOccurrenceRoute = (
  networkSlug: string,
  eventSlug: string,
  timestamp: number
): string => `/${networkSlug}/events/${eventSlug}/o/${timestamp}`;

export const postDetailRoute = (networkSlug: string, postId: string): string =>
  `/${networkSlug}/posts/${postId}`;

export const challengesRoute = (networkSlug: string): string =>
  `/${networkSlug}/challenges`;

export const challengeRoute = (networkSlug: string, slug: string): string =>
  `/${networkSlug}/challenges/${slug}`;

export const challengesV1Route = (networkSlug: string): string =>
  `/${networkSlug}/challenges-v1`;

export const networkAdminRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin`;

export const networkAdminChallengesV1Route = (networkSlug: string): string =>
  `/${networkSlug}/admin/challenges-v1`;

export const networkAdminChallengesRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/challenges`;

export const networkAdminChallengeRoute = (
  networkSlug: string,
  challengeSlug: string
): string => `/${networkSlug}/admin/challenges/${challengeSlug}`;
export const networkAdminChallengeTemplateRoute = (
  networkSlug: string,
  slug: string
): string => `/${networkSlug}/admin/challenge-templates/${slug}`;

export const networkAdminPeerRecognitionRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/peer-recognition`;

export const networkAdminRewardsRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/rewards`;

export const networkAdminRedemptionsRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/redemptions`;

export const networkAdminMembersRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/members`;
export const networkAdminRolesRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/roles`;

export const networkAdminNetworkCategoriesRoute = (
  networkSlug: string
): string => `/${networkSlug}/admin/categories`;

export const networkAdminNewsfeedRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/newsfeed`;

export const networkAdminTagsRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/manage-tags`;

export const networkAdminJobsRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/jobs`;

export const networkAdminAnalyticsRoute = (networkSlug: string): string =>
  `/${networkSlug}/admin/analytics`;

export const teamsRoute = (networkSlug: string): string =>
  `/${networkSlug}/teams`;

export const teamDetailRoute = (networkSlug: string, teamId: string): string =>
  `/${networkSlug}/teams/${teamId}`;

export const myTeamRoute = (networkSlug: string): string =>
  `/${networkSlug}/my-team`;

export const myTeamPostDetailRoute = (networkSlug: string, postId: string) =>
  `/${networkSlug}/my-team/posts/${postId}`;

export const leaderboardsRoute = (networkSlug: string): string =>
  `/${networkSlug}/leaderboards`;

export const directoryRoute = (networkSlug: string): string =>
  `/${networkSlug}/directory`;

export const ulyssesPactRoute = (networkSlug: string): string =>
  `/${networkSlug}/ulysses-pact`;

export const groupsRoute = (networkSlug: string): string =>
  `/${networkSlug}/groups`;
export const eventDateRoute = (
  networkSlug: string,
  month: string,
  view: string
): string => `/${networkSlug}/events?month=${month}&view=${view}`;

export const groupDetailRoute = (
  networkSlug: string,
  groupSlug: string
): string => `/${networkSlug}/groups/${groupSlug}`;

export const docsRoute = (networkSlug: string): string =>
  `/${networkSlug}/docs`;

export const jobsRoute = (networkSlug: string): string =>
  `/${networkSlug}/jobs`;

export const docDetailRoute = (networkSlug: string, docSlug: string): string =>
  `/${networkSlug}/docs/${docSlug}`;

export const folderDetailRoute = (networkSlug: string, folderSlug: string) =>
  `/${networkSlug}/docs/folder/${folderSlug}`;

export const groupPostDetailRoute = (
  networkSlug: string,
  groupSlug: string,
  postId: string
): string => `/${networkSlug}/groups/${groupSlug}/posts/${postId}`;

export const docEditRoute = (networkSlug: string, docSlug: string): string =>
  `/${networkSlug}/docs/${docSlug}/edit`;

export const docCreateRoute = (
  networkSlug: string,
  selectedFolderId: string | null | undefined
): string => `/${networkSlug}/docs/new?folderId=${selectedFolderId}`;

// chat

export const chatsRoute = (networkSlug: string): string =>
  `/${networkSlug}/chat`;

export const chatRoute = (networkSlug: string, chatId: string): string =>
  `/${networkSlug}/chat/${chatId}`;

export const newChatRoute = (networkSlug: string): string =>
  `/${networkSlug}/chat/new`;

// onboarding

export const onboardingRoutes = {
  account: "/onboarding/account",
};

// user routes
export const userRoute = "/user";
export const userPreferencesRoute = "/user/preferences";
export const userActivityRoute = "/user/activity";

export const profileRoute = (uid: string): string => `/profile/${uid}`;

// static routes
export const supportRoute = "/support";
export const signoutRoute = "/signout";

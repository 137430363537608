import * as amplitude from "@amplitude/analytics-browser";
import {
  LiteProfile,
  Profile,
} from "@converge-collective/common/models/Profile";
import { UserClaims } from "@converge-collective/common/models/User";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ActionCodeSettings,
  User,
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCustomToken,
  signInWithEmailLink,
  updatePassword,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import cookie from "js-cookie";
import { useRouter } from "next/router";
import { memo, useEffect, useState } from "react";
import { useFirestore } from "reactfire";
import { converters } from "~/lib/converter";
import { createAlert } from "~/lib/globalAlerts";
import { useLoggedInState } from "~/lib/useLoggedInState";

const userCookieName = "auth";
const userCookieExpires = 1;

const updateCookie = async (firebaseUser: User) => {
  console.log("update auth cookie", firebaseUser);
  // any time the token is updated store it in a cookie
  const token = await firebaseUser.getIdToken();
  const { uid, email } = firebaseUser;
  const user = {
    uid,
    token,
    email: email,
  };
  cookie.set(userCookieName, JSON.stringify(user), {
    expires: userCookieExpires,
  });
};

const removeCookie = () => {
  cookie.remove(userCookieName);
};

/**
 * Handle changes to the user's Firebase auth state.
 * Handle Firebase `getRedirectResult` when the user is redirected back to the
 * app after signing in through social or oauth providers.
 *
 * When a user logs in, we:
 *
 * 1. Update a cookie with their auth token. This allows us to authenticate the
 * user server-side when accessing API routes.
 *
 * When a user logs out, we:
 *
 * 1. Delete their auth cookie
 */
export const UserState = memo(function UserState(): React.ReactElement {
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore();
  const router = useRouter();
  // fbct = firebase custom token
  const { fbct, email } = router.query;

  const [magicLoginError, setMagicLoginError] = useState<Error>();

  const { setUser, setUserClaims, network, profile } = useLoggedInState();

  console.log("userState", { user, magicLoginError });

  useEffect(
    function loginWithCustomToken() {
      if (fbct) {
        console.log("loginWithCustomToken", { fbct });
        signInWithCustomToken(auth, fbct as string)
          .then((firebaseUser) => handleUserLoginOrUpdate(firebaseUser.user))
          .catch(console.error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fbct]
  );

  useEffect(
    function updateIdToken() {
      // when needsAuthRefresh is true update the token and set it back to false
      if (user && profile?.needsAuthRefresh) {
        console.log("refreshing id token for profile", { profile });
        user.getIdToken(true);
        updateDoc(profile.ref, {
          needsAuthRefresh: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.uid, profile?.needsAuthRefresh]
  );

  const setProfileState = async (uid: string) => {
    const profileRef = doc(firestore, `profiles/${uid}`);

    // wrap this in try catch - it's ok if it fails, which could be the case if
    // the user profile has not yet been created
    try {
      await updateDoc(profileRef, {
        lastSeenAt: new Date(),
      });
    } catch (e) {
      console.error(
        "error trying to set lastSeenAt.  profile must not exist. creating the profile.",
        { e }
      );

      // they might have logged in with a phone number
      const phoneNumber = user?.phoneNumber;
      // in this case auto create the profile (even if it's blank)
      const partialProfile: Profile = {
        createdAt: new Date(),
        id: uid,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        termsAccepted: false,
        lastSeenAt: new Date(),
        name: "",
        firstName: "",
        lastName: "",
      };
      if (phoneNumber) {
        partialProfile.phoneNumber = phoneNumber;
      }
      await setDoc(profileRef, partialProfile);
    }
  };
  /** Handle for onIdTokenChanged and onAuthStateChanged */
  const handleUserLoginOrUpdate = async (firebaseUser: User) => {
    setUser(firebaseUser || undefined);
    if (firebaseUser) {
      amplitude.setUserId(firebaseUser.uid);
      const identify = new amplitude.Identify();
      firebaseUser.email && identify.set("email", firebaseUser.email);
      firebaseUser.displayName &&
        identify.set("name", firebaseUser.displayName);
      firebaseUser.photoURL && identify.set("photoURL", firebaseUser.photoURL);
      firebaseUser.phoneNumber &&
        identify.set("phoneNumber", firebaseUser.phoneNumber);
      amplitude.identify(identify);
      console.log("handleUserLoginOrUpdate, updating cookie", { firebaseUser });
      updateCookie(firebaseUser);
      // as soon as the user logs in save the user claims in global state
      const { claims } = await firebaseUser.getIdTokenResult();
      setUserClaims(claims as UserClaims);
      await setProfileState(firebaseUser.uid);
      console.log("UserState profile claims", { profile, claims });
      console.log("Updating profile lastSeenAt", { profile });
    } else {
      // delete the cookie to ensure they aren't still signed on on the
      // server
      removeCookie();
      setUserClaims(undefined);
    }
  };

  /** Handle onIdTokenChanged */
  useEffect(() => {
    return auth.onIdTokenChanged(handleUserLoginOrUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Handle onAuthStateChanged */
  useEffect(function setupAuthStateChangeListener() {
    return auth.onAuthStateChanged(handleUserLoginOrUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** fetch claims any time the user or network change */
  useEffect(
    function fetchClaims() {
      const user = auth.currentUser;
      if (user) {
        user.getIdTokenResult().then(({ claims }) => {
          setUserClaims(claims as UserClaims);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.uid, network?.id]
  );

  /**
   * refresh the token every 10 minutes
   * this keeps the user logged in, and refreshes their claims as well
   */
  useEffect(function refreshIdToken() {
    const tenMinutes = 10 * 60 * 1000;
    const handle = setInterval(async () => {
      const user = auth.currentUser;
      try {
        if (user) await user.getIdToken(true);
      } catch (e) {
        console.error("error refreshing token", { e });
      }
    }, tenMinutes);
    // clean up setInterval
    return () => clearInterval(handle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** Handle social redirects */
  useEffect(() => {
    // social sign in produces a redirect. in that case, make sure they have a
    // profile - if not, create it, and send them to onboarding flow
    getRedirectResult(auth).then(async (result) => {
      console.log("getRedirectResult", { result });
      if (!result) return;

      const { user } = result;
      if (user) {
        // make sure they have a profile
        const { uid } = user;
        const profileRef = doc(firestore, "profiles", uid).withConverter(
          converters.profile.read
        );
        const profileSnap = await getDoc(profileRef);
        const profile = profileSnap.data();
        // NOTE: it might exist, because of the timezone updater, so also check
        // whether name is set (Note: we could use `zod` to verify it's a full
        // profile if we wanted a better guarantee)
        if (
          !profileSnap.exists() ||
          !profile ||
          (!profile.firstName && !profile.lastName)
        ) {
          console.log(
            "getRedirectResult: creating profile for new user via social sign in",
            { uid, profile }
          );
          const name = user.displayName || "";
          const [firstName, lastName] = name.split(" ", 2);
          const photoURL = user.photoURL;
          // create a partial profile for new user that just signed in via social
          const partialProfile: Partial<LiteProfile> = {
            createdAt: new Date(),
            id: uid,
            name,
            firstName,
            lastName,
            ...(photoURL && { photoURL }),
          };
          await setDoc(profileRef, partialProfile, { merge: true });
          router.push("/onboarding/account");
        } else {
          console.log("getRedirectResult: profile already exists for user", {
            uid,
            profileSnap,
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    function handleSignInWithEmail() {
      if (
        email &&
        typeof email === "string" &&
        isSignInWithEmailLink(auth, window.location.href)
      ) {
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email)
          .then((result) => {
            // You can access the new user by importing getAdditionalUserInfo
            // and calling it with result:
            // getAdditionalUserInfo(result)
            // You can access the user's profile via:
            // getAdditionalUserInfo(result)?.profile
            // You can check if the user is new or existing:
            const additionalUserInfo = getAdditionalUserInfo(result);
            console.log("user logged in with", { additionalUserInfo });
            // if user logged in with magic link show a modal that allows them
            // to set a password
            setIsPasswordModalOpen(true);
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            console.error("error signing in with email", { error });
            // show an error UI with a button to resend the email
            if (error instanceof Error) {
              setMagicLoginError(error);
            }
          });
      }
    },
    [auth, email]
  );

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [wasEmailSent, setWasEmailSent] = useState(false);
  // when a user logs in with magic link allow them to change their password
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  if (isPasswordModalOpen) {
    return (
      <Dialog maxWidth="sm" fullWidth open>
        <DialogTitle>Set a password</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Typography>Please set a password to continue</Typography>
            <TextField
              label="Password"
              required
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={Boolean(passwordError && passwordError.length > 0)}
              helperText={passwordError}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          {passwordError !== undefined && (
            <Button onClick={() => setIsPasswordModalOpen(false)}>
              Cancel
            </Button>
          )}
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={async () => {
              const auth = getAuth();
              const user = auth.currentUser;
              if (!user) {
                setPasswordError("Please login first");
                return;
              }
              if (newPassword.length < 6) {
                setPasswordError("Password must be at least 6 characters");
                return;
              }
              setIsLoading(true);
              try {
                await updatePassword(user, newPassword);
                setIsPasswordModalOpen(false);
                createAlert({
                  severity: "info",
                  message: "Password updated",
                });
                setPasswordError(undefined);
              } catch (e) {
                setPasswordError("Error updating password: " + e.message);
              } finally {
                setIsLoading(false);
              }
            }}
          >
            Set Password
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  }

  // make sure to check !user because if the user refreshes they'll get an error
  // (because the code was already used) but they'll still be logged in, so we
  // don't need to show the error
  if (!user && (wasEmailSent || magicLoginError !== undefined)) {
    return (
      <Container sx={{ my: 4 }} maxWidth="md">
        <Paper sx={{ p: 2 }}>
          {wasEmailSent && (
            <Alert
              severity="success"
              sx={{ p: 3, "& .MuiAlert-action": { alignItems: "center" } }}
            >
              We sent an email to <b>{email}</b> with a link to sign in. Please
              check your email.
            </Alert>
          )}

          {magicLoginError && (
            <Alert
              sx={{
                "& .MuiAlert-action": {
                  alignItems: "center",
                },
              }}
              severity="error"
              action={
                <LoadingButton
                  loading={isSendingEmail}
                  sx={{ textWrap: "no-wrap", whiteSpace: "nowrap" }}
                  variant="outlined"
                  color="error"
                  onClick={async () => {
                    const emailString =
                      email && typeof email === "string" ? email : "";
                    setIsSendingEmail(true);
                    const url = new URL(window.location.href);
                    url.search = "";
                    url.searchParams.append("email", emailString);
                    // TODO
                    const actionCodeSettings: ActionCodeSettings = {
                      // URL you want to redirect back to. The domain (www.example.com) for this
                      // URL must be in the authorized domains list in the Firebase Console.
                      url: url.toString(),
                      // This must be true.
                      handleCodeInApp: true,
                    };
                    console.log("send email", { actionCodeSettings });
                    try {
                      await sendSignInLinkToEmail(
                        auth,
                        emailString,
                        actionCodeSettings
                      );
                      setWasEmailSent(true);
                      setMagicLoginError(undefined);
                    } catch (e) {
                      setWasEmailSent(false);
                      e instanceof Error && setMagicLoginError(e);
                      console.log(e);
                    } finally {
                      setIsSendingEmail(false);
                    }
                  }}
                >
                  Send Sign In Email
                </LoadingButton>
              }
            >
              <AlertTitle>Unable to sign in</AlertTitle>
              Looks like that link expired. Please generate a new email sign in
              link.
            </Alert>
          )}
        </Paper>
      </Container>
    );
  }

  return <></>;
});
